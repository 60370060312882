.photos {
    display: flex;
    /*overflow-y: auto;*/
    flex-wrap: wrap;
    /*width: 800px;*/
    justify-content: space-around;
}
.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 200px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .card-name {
    text-align: center;
  }
  a {
    text-decoration: none;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .card-pic {
    width: 200px;
    object-fit: contain;
  }

.PhotoInfo {
    /*width: 90%;
    height: 90vh;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    top: 80px;*/
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /*background-color: antiquewhite;*/
    /*padding: 25px;*/
    overflow-y: auto;
}
.PhotoInfo svg{
    position: absolute;
    top: 100px;
    left: 20px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    color: #ffffff;
    border-radius: 5px;
}
.photoinfo-description {
    margin: 20px 80px 150px 80px;
    padding: 15px;
    /*border: 1px black solid;
    border-radius: 20px;
    background-color: white;*/
}
.greybackground {
  background-color: black;
  opacity: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}