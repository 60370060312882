@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  /*font-family: "Quicksand";*/
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;

  /*color: #333;*/
}
ul {
  list-style-position: inside;
}

.admin {
  display: flex;
  flex-direction: column;
}
/*.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}*/

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 100vh;*/
  /*background-color: #f2f2f2;*/
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
}

.form-group {
  display: flex;
  flex-direction: column;
  /*margin-bottom: 20px;*/
  margin: 10px 0px;
}
.errMsg {
  background-color: lightpink;
  color: red;
  margin: 10px 0px 0px 0px;
  padding: 10px;
}

.content {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}
.client-and-salesrep{
  /*width: 600px;*/
  display: flex;
  justify-content: space-between;
  margin: 15px 0px 15px 0px;
}
.client{
  justify-content: left;
}
.salesrep{
  display: flex;
  justify-content: right;
  text-align: right;
  flex-direction: column;
  margin-top: 5px;
}
::placeholder{
  color: #000;
  stroke-width: 110%;
}
.submit-order{
  /*width: 600px;*/
  display: flex;
  justify-content: flex-end;
}
.submit-button{
  width: 250px;
  height: 75px;
  background-color: rgb(55, 155, 255);
  color: white;
  border: 0px;
  border-radius: 15px;
  margin: 5px;
  font-size: 1.5em;
}
.submit-button:hover{
  background-color: rgb(46, 128, 210);
}
hr{
  /*width: 600px;*/
  /*margin: 15px;*/
  color: #060b26;
}
.subtotal{
  display: flex;
  /*width: 600px;*/
  flex-direction: column;
  justify-content: right;
  text-align: right;
}
.total{
  color: black;
  border: 1px solid;
  border-radius: 5px;
  margin: 0px 0px 20px auto;/*365px;*/
  padding: 4px;
}
.clientlist {
  display: flex;
  flex-direction: column;
  /*align-items: stretch;*/
}
.client-list{
  display: flex;
  justify-content: space-between;
  background-color: rgb(244, 244, 244);
  padding: 5px;
  margin: 10px;
  border: 1px solid grey;
  border-radius: 10px;
  /*align-items: center;*/
}
.client-info{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.ciright {
  margin-right: 20px;
}
.cileft {
  margin-right: 20px;

}
.client-info .checkbox {
  margin-right: 5px;
}
.client-info-home{
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.client-buttons{
  text-decoration: none;
  font-size: 18px;
  height: 50px;
}
.client-button{
  border: 1px solid darkgray;
  border-radius: 10px;
}
.client-button a{
  text-decoration: none;
  color: rgb(17, 93, 206);
}
.client-order-icon{
  vertical-align: middle;
  margin: 5px;
}
.client-search{
  /*width: 560px;*/
  margin: 10px 10px 10px 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.clientnames {
  padding: 5px 10px;
  border: 0px;
  display: flex;
  background-color: transparent;
  color: black;
  /*border-top: 1px solid black;*/
  /*border-bottom: 1px solid black;*/
}
.clientnames-list {
  padding: 5px 10px;
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  /*border-bottom: 1px solid black;*/
}
.clientnames-list button{
  margin: 0px 0px 5px 0px;
}
.ClientInfo {
  width: 60%;
  max-width: 700px;
  /*height: 60vh;*/
  position: fixed;
  align-self: center;
  /*top: 80px;
  left:0;*/
  /*display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;*/
  background-color: white;
  padding: 25px;
  border: 1px solid black;
  border-radius: 15px;
  z-index: 1;
}
.clientinfo-header {
  display: flex;
  justify-content: space-between;
}
.clientinfo-header svg{
  /*position: fixed;
  top: 90px;
  right: 10px;*/
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0,0,0,0.4);
  color: #ffffff;
  border-radius: 5px;
}
.clientinfo-footer {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  color: rgb(49, 167, 240);
  margin-top: 15px;
}
.clientinfo-footer svg{
  width: 2rem;
  height: 2rem;
  padding: 5px;
  /*color: rgb(49, 167, 240);*/
  border-radius: 5px;
}
.client-info p{
  padding-top: 5px;
}
.client-info p:empty{
  padding-top: 0px;
}
.client-info span{
  margin: 5px 0px;
  display: flex;
}
.client-info label{
  width: 100px;
}
#client-ext-label{
  width: 30px;
}
#ext{
  width: 50px;
}
.client-ext{
  width: 40px;
}
.cigar{
  /*width: 600px;*/
  display: flex;
  margin: 5px;
}
.cigar-col{
  margin: auto;
}
.cigar-brand{
  width: 140px;
  margin: auto;
}
.cigar-name{
  width: 120px;
  margin: auto;
}
.cigar-brand-and-name{
  width: 290px;
  margin: auto
}
.cigar-blend{
  width: 100px;
  margin: auto;
}
.cigar-size{
  width: 100px;
  margin: auto;
}
.cigar-qty{
  width: 40px;
  margin: auto;
}
.cigar-discount{
  width: 35px;
  margin: auto;
}
.cigar-price{
  width: 40px;
  text-align: right;
  margin: auto;
}
.photo{
  width: 600px;
}
.hcol{
  text-align: center;
  margin: auto;
}
.trash {
  height: 23px;
  align-self: center;
  margin-right: 8px;
}
.css-b62m3t-container {
  width: 280px;
}
.add-cigar{
  size: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
}
.cigar-list table {
  /*border: 1px solid;*/
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
  padding: 3px;
}
.cigar-list th {
  /*border: 1px solid;*/
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
  padding: 3px;
}
.cigar-list td {
  /*border: 1px solid;*/
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
  padding: 3px;
}
.cigar-col::placeholder{
  color: #AAA;
}
.row-selected{
  /*background-color: #c3eaa9;*/
  background-color: rgb(195, 225, 255);
}
.ca-tax-input{
  width: 30px;
  margin-left: 10px;
  text-align: center;
}
.ca-tax-span{
  margin-left: 0px;
}
.save-tax-button{
  width: 50px;
  height: 35px;
  background-color: rgb(55, 155, 255);
  color: white;
  border: 0px;
  border-radius: 15px;
  margin: 5px 10px;
}
.save-email-button{
  width: 50px;
  height: 35px;
  background-color: rgb(55, 155, 255);
  color: white;
  border: 0px;
  border-radius: 15px;
  margin: 4px 10px 0px 0px;
}
.save-tax-button:active{
  background-color: rgb(46, 128, 210);
}
input[type=number]{
  -moz-appearance: textfield;
  appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.spacer {
  flex-grow: 1;
}
.trashicon {
  color: rgb(255, 51, 51)
}
.orderpdf {
  text-align: start;
  font-size: 16px;
  background-color: #ffffff;
  border: 0px;
}
.orderpdf-selected {
  text-align: start;
  font-size: 16px;
  background-color: #7CBDFF;
  border: 0px;
}
label.changePassword {
  width: 140px;
}
.order-list {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid rgb(194, 194, 194);
  border-radius: 5px;
  margin-top: 5px;
  padding: 4px;
}
.reset-order {
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
}
.reset-order button{
  border: 1px solid grey;
  border-radius: 5px;
}
.summary {
  display: flex;
  margin: 3px 0px;
}
.summary input{
  margin-right: 5px;
  width: 45px;
}
.summary input::placeholder{
  color: #AAA;
}
.discount-toggle {
  display: flex;
  margin: 5px 0px;
  margin-left: 30%;
}
.boxes-available{
  color: blue;
  margin-left: 3px;
}
.box-buttons {
  display: flex;
  margin-right: 5px;
}
.box-buttons p{
  margin: 0 5px;
}
.box-buttons button{
  border: 1px solid grey;
  border-radius: 50%;
  width: 23px;
  height: 23px;
}
.box-buttons button:disabled{
  background-color: white;
}
.box-buttons .plus {
  background-color: lightgreen;
}
.box-buttons .minus {
  background-color: pink;
}
.react-toggle {
  margin: 0px 5px;
}
.corediscount-input {
  margin-left: 5px;
  width: 30px
}
.summary-list table, td, th {
  /*border: 1px solid;*/
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  padding: 3px 15px;
}
.percent-off {
  max-width: 70px;
}
.summary-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 15px 0px;
}
.order-cigar-list  {
  text-align: center;
}
.order-cigar-list table {
  /*border: 1px solid;*/
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
  padding: 3px;
}
.order-cigar-list th {
  /*border: 1px solid;*/
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  width: 100%;
  padding: 3px;
}
.order-cigar-list td {
  /*border: 1px solid;*/
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
  padding: 3px;
}
.order-pdf-header {
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}
.order-cigars {
  text-align: center;
}
.order-pdf-header-titles {
  display: flex;
  justify-content: space-between;
}
.order-pdf-info {
  margin-top: 0px;
}
.order-notes-input {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
}
.print-order-notes textarea {
  width: 100%;
  border: 0px solid lightgray;
  border-radius: 5px;
  height: auto;
  resize: none;
}
.intl {
  display: flex;
  margin: 5px;
}
.intl label{
  padding: 0px 10px 0px 3px;
}
.intl-client-toggle {
  display: flex;
  margin: 5px 0px;
}
.cig-per-box {
  width: 60px;
}